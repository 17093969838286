@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    src: url('./MuseoSans_300.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 500;
    src: url('./MuseoSans_500.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    font-style: italic;
    src: url('./MuseoSans_300_Italic.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 500;
    font-style: italic;
    src: url('./MuseoSans_500_Italic.otf') format('opentype');
}
